<template>
  <div class="cyText">
    <div class="containerOne">{{ list.articleTitle }}</div>
    <div class="containerTwo">
      <!-- <icon class="iconfont iconshijian"></icon> -->
    </div>
    <div v-html="list.articleContent"></div>
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  name: "cyText",
  components: {},
  props: {
    dynamicId: {
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {
      list: {},
    };
  },
  mounted() {
    console.log(this.dynamicId);
    this.search();
  },
  methods: {
    search() {
      api.taoruHomePageDynamicDetail({ dynamicId: this.dynamicId }, (res) => {
        console.log(res);
        this.list = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.cyText {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  padding: 24px;
  .containerOne {
    // height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 40px;
    letter-spacing: 1px;
  }
  .containerTwo {
    margin-top: 8px;
    margin-bottom: 24px;
    height: 24px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c0c0c0;
    line-height: 24px;
    display: flex;
    align-items: center;
    .iconshijian {
      font-size: 14px;
      margin-right: 5px;
    }
  }
}
</style>
